#map {
  width: 79%;
  height: 92.9vh;
  box-shadow: 0px 0px 1px 1px #d0d0d0;
}
.reactPivot-csvExport {
  display: none !important;
}
.legenda{
  position: absolute;
    bottom: 0;
    right: 0;
}

.image-item {
  width: 190px !important;
  margin: 15px;
  float: left;
}

.menu-item .menu-link .aling-top{
  align-items: flex-start !important;
}
.form-check.form-check-solid .form-check-input:checked {
  background-color: #50cd89 !important;
}
.form-check.form-check-solid .form-check-input {
  background-color: #f0f0f0 !important;
}

.map-info{
  position: absolute;
    top: 11px;
    left: 50%;
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px 2px 1px 1px #d0d0d0;
    border-radius: 5px;
    border: 1px solid #d0d0d0;

}

#infoimovelsemlote{
  color:#ff0000;
  
}

.band-title {
  text-align: center;
}
.band {
  display: block;
  width: 100%;
  height: 10px;
  background: linear-gradient(to right, #fff, #fff);
}
.band-labels {
  display: flex;
  justify-content: space-between;
  /* display: inline-block; */
  /* text-align: center; */
}

.band-label {
  padding: 5px;
}
.legenda {
  background-color: #fff;
  border-radius: 3px;
  bottom: 25px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right:17%;
  z-index: 3;
  color: #333;
  width: auto;
}
#maptype {
  position: absolute;
  top: 15px;  
  border: 2px solid #d0d0d0;
  /* width: 100px;
  height: 75px; */
  border-radius: 8px;
  border: 2px solid #fff;
  z-index: 99;
  right: 15px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
}
#maptype img {
  border-radius: 8px;
}
#maptype .maptype-item img {
  border-radius: 8px;
}
.font-destaque{
  font-weight: 500;
}
.pac-container {
  z-index: 1100 !important;
}
.maptype-active{
  z-index: 9999;
}

#maptype .maptype-item {
  /* width: 100px;
  height: 70px; */
  /* border: 1px solid #d0d0d0; */
  position: absolute;
  left: 0;
  z-index: 0;
}

.rc-slider-handle{
  transform: translateX(0%) !important;
  z-index: 0;
}
.tootip-format{
  font-size: 12px;
}
.rmsc .dropdown-container{
  border: 1px solid #e4e6ef !important;
}
#maptype:hover  {
  /* width: 400px; */
}
.map-name {
  display: none;
  text-align: center;
  padding-top: 3px;
}
#maptype:hover .map-name {
  display: block;
}
#maptype:hover .maptype-item {
  /* background-color: #0077b3; */
  position: initial;
  margin-left: 6px;

}

.menu-map-item :hover{
  /* background-color: #efefef !important; */

}
.menu-map-item .active{
  background-color: #d5ffc6 !important;

}


.sub-menu-hide {
  /* width: 45% !important;  
    background-color: #fff; */
  display: none !important;
}
.sub-menu-show {
  /* width: 45% !important;  
    background-color: #fff; */
  display: block !important;
}
.titulo-servicos-grupo {
  /* padding: 15px; */
  /* background-color: #f1f1f1; */
  margin: 0;
  /* color: #238338 !important; */
  height: 55px;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link.active .bullet-custom,
.nav.nav-pills.nav-pills-custom .nav-link.active .bullet-custom {
  display: block;
}

.scroll {
  padding-right: 3px;
}
#sub-menu {
  width: 40rem !important;
  background-color: #fff;
  z-index: 999;
  position: fixed;
  top: 0;
  right: 0;
  /* border-left: 1px solid #d0d0d0; */
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  /* display: none !important; */
}

.menu li {
  height: 36px;
}
.menu li ul {
  position: relative;
  top: -35px;
  left: 150px;
  background-color: #fff;
  display: none;
  width: auto;
}
.menu li:hover ul,
.menu li.over ul {
  display: block;
}

.menu li ul li {
  /* border:1px solid #c0c0c0; */
  display: block;
  width: 150px;
}

.layer-format-content {
  background-color: #fff;
  padding: 15px 15px 5px 8px;
  border-radius: 10px;
}

#menu-topo-filtro {
  box-shadow: 0px 4px 4px 3px #d0d0d0;
  margin-bottom: 1px;
  top: 0;
  width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  height: 65px;
  background-color: #fff;
}
.bottom-bar {
  background-color: #ffffff; 
  /* position: absolute; */
  width: 100%;
  /* bottom: 0; */
  box-shadow: 8px 5px 6px 4px #d0d0d0;
}
.rstm-toggle-icon-symbol{
  line-height: 1.6rem !important;
}
.layer-name {
  width: 57%;
  display: inline-block;
}
.border-left-layers {
  border-left: 1px solid #e5e5e5  !important;
  border-radius: 0 !important;
  padding: 1rem 1.4rem !important;
}
.item-config {
  border: 1px #d0d0d0;
  margin-bottom: 10px;
}

.folder i {
  color: #1ea377 !important;
  font-weight: 600;
}
.folder {
  /* color: #1ea377 !important; */
  /* background-color: #f5f5f5 !important; */
  /* font-weight: 600; */
}

.reactPivot {
  margin-top: 0 !important;
  box-shadow: 0px 0px 20px 0px rgba(76, 87, 125, 0.02) !important;
}
.item-config-align-title {
  margin-left: 20px;
  color: #333;
  font-weight: 500;
}
.item-config-align {
  margin-left: 24px;
  margin: 0px 5px;
}
/* .item-config-align::before{
    content: " ";
    display: block;
    position: absolute;
    top: 99px;
    bottom: 0;
    left: 28px;
    border-left: 1px solid #e7e7e7

} */
.menu-link table {
  width: 100%;
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.185rem;
  --bs-spinner-animation-speed: 0.65s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.item-config::before {
  content: '';
  display: block;
  background: url('/src/img/arrow-right-svgrepo-com.svg') no-repeat;
  width: 10px;
  height: 10px;
  position: absolute;
  float: left;
  margin: 4px 5px 0 0;
  border: 1px #d0d0d0;
  background-position: bottom;
  background-size: contain;
}
.menu-item .menu-link .menu-actions {
  display: flex;
  align-items: stretch;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 5px;
  width: 20px;
  height: 20px;
}
.color-select {
  width: 15%;
  height: 20px;
  display: block;
  float: left;
}
.select-layer-type {
  float: left;
}
.select-layer-type-value {
  border: 1px solid #d0d0d0;
  border-radius: 9px;
  margin-top: 12px;
}

.select-layer-type-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-layer-type-background);
  border: 2px solid var(--select-layer-type-border);
  color: var(--select-layer-type-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-layer-type-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-layer-type-is-multiple .select-layer-type-input {
  margin-bottom: -2px;
}

.select-layer-type-is-multiple .select-layer-type-input {
  border-radius: 3px 3px 0 0;
}

.select-layer-type-input::-webkit-search-decoration,
.select-layer-type-input::-webkit-search-cancel-button,
.select-layer-type-input::-webkit-search-results-button,
.select-layer-type-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-layer-type-input[readonly] {
  cursor: pointer;
}

.select-layer-type-is-disabled .select-layer-type-input {
  cursor: not-allowed;
}

.select-layer-type-container:not(.select-layer-type-is-disabled).select-layer-type-has-focus
  .select-layer-type-input,
.select-layer-type-container:not(.select-layer-type-is-disabled) .select-layer-type-input:hover {
  border-color: var(--select-layer-type-selected);
}

.select-layer-type-select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-layer-type-border);
  overflow: auto;
  max-height: 360px;
}

.select-layer-type-container:not(.select-layer-type-is-multiple) .select-layer-type-select {
  position: absolute;
  z-index: 2;
  top: 100px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-layer-type-container:not(.select-layer-type-is-multiple).select-layer-type-has-focus
  .select-layer-type-select {
  display: block;
}
.select-layer-type-icon {
}
.select-layer-type-has-focus .select-layer-type-select {
  border-color: var(--select-layer-type-selected);
}

.select-layer-type-options {
  list-style: none;
  padding: 0;
}

.select-layer-type-option,
.select-layer-type-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-layer-type-background);
  border: none;
  outline: none;
  font-family: var(--select-layer-type-font);
  color: var(--select-layer-type-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-layer-type-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-layer-type-is-highlighted,
.select-layer-type-option:not(.select-layer-type-is-selected):hover {
  background: var(--select-layer-type-highlight);
}

.select-layer-type-is-selected {
  font-weight: bold;
  color: var(--select-layer-type-selected);
}

.select-layer-type-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-layer-type-border);
  color: var(--select-layer-type-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}
.select-layer-type-row {
  float: left;
  width: 27%;
  height: 87px;
  box-shadow: 2px 1px 5px 0px #d0d0d0;
  margin: 3px;
  padding: 5px;
}

.select-layer-type-option span {
  display: block;
  text-align: center;
}
.select-layer-type-row:not(:first-child) .select-layer-type-group-header {
  margin-top: 10px;
}

.select-layer-type-row:not(:last-child) .select-layer-type-group-header {
  margin-bottom: 10px;
}

.accordion-button {
  color: #333 !important;
}
.cardMap {
  /* padding: 5px; */
  border-radius: 10px;
  /* border: 1px solid #d2d6ea; */
  /* width: 103px; */
  float: left;
  margin-left: 10px;
}

.cardMap h5 {
  text-align: center;
  font-size: 1.5rem;
}

.cardMap button {
  width: 100% !important;
  padding: 8px;
  border-radius: 10px;
  border: 1px solid #d2d6ea;
  background-color: #f5f8fa;
  color: #7e8299;
  font-weight: 500;
  transition: 0.3s;
}

.cardMap button:hover {
  background-color: #20a475;
  color: white;
}

.menu-item .menu-content {
  /* padding: 18px 0 0px !important; */
}
.map-container {
  height: 92.9vh;
}
.sidebar {
  position: absolute;
  top: 71px;
  left: 13px;
  background-color: #fff;
  padding: 4px 15px;
}

.nav-explorer li button {
  font-size: 1.3rem;
  margin-right: 0rem;
  padding: 0.5rem 1rem;
}
/* .nav-explorer li button.active {
  font-weight: 600 !important;
  color: #20a745 !important;
  border-bottom: 2px solid #20a745 !important;
  /* padding: 5px !important; */
/* }  */

.buttonIconExplorer {
  display: inline !important;
  /* padding: 8px !important; */
  background-color: #f5f8fa !important;
  margin-left: 5px !important;
}

.menu-td {
  width: 100px;
}
#MenuTd {
  /* background-color: #fff; */
  width: 52px;
  position: fixed;
  top: 136px;
  left: 443px;
  z-index: 9;
  border-radius: 57%;
}
#MenuTd .conteudo {
  /* margin-top: 54px; */
  border-left: 1px solid #e4eaec;
  /* height: 94.2%; */
  background-color: #f7f7f7;
  border-right: 1px solid #e4eaec;
  box-shadow: 1px 1px 2px 1px #d0d0d0;
  text-align: center;
  border-radius: 7px;
}
#MenuTd i {
  color: #727272;
  font-size: 20px;
}

#sencond-content {
  width: 302px;
  position: absolute;
  background-color: #fff;
  height: 94%;
  top: 53px;
  left: 481px;
  z-index: 9;
  border-right: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
}
.navigation {
  padding: 5px;
}
.navigation-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  border-radius: 4px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.navigation a {
  color: black;
  padding: 12px;
  margin: 0;
  text-decoration: none;
  display: block;
}
.navigation a:hover {
  background-color: #ddd;
  border-radius: 4px;
}
.navigation:hover .navigation-content {
  display: block;
}

.hide {
  display: none !important;
}
.show {
  display: block !important;
}
/* .offcanvas-body{
    box-shadow: 1px 1px 7px 2px #d0d0d0;
    background-color: #fff;
} */

#click-context-menu {
  position: absolute;
  /* background-color: #ff0000; */
  /* width: 100px;
    height: 100px; */
  top: 0px;
  left: 0px;
}

body.swal2-height-auto {
  height: inherit !important;
}



.offcanvas {
  display: none;
  position: fixed;
   width: 29.5rem; 
  height: 100%;
  z-index: 9;
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  right: 0;
  top: 0;
}

.position-map {
  /* position: absolute; */
  /* z-index: 9;
    bottom: 15px;
    left: 50%;
    background-color: #fff;
    padding: 4px;
    font-size:15px ;
    margin-left: -100px;
    box-shadow: 1px 1px 1px 1px #d0d0d0;
    border: 1px solid #d0d0d0;
    padding: 10px 15px */
}

.buttons-map-draw {
  position: absolute;
  top: 35%;
  /* left: 32%; */
  width: 50px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
/* 
  .dropdown-check-list {
    display: inline-block;
  }
  
  .dropdown-check-list .anchor {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 5px 50px 5px 10px;
    border: 1px solid #ccc;
  }
  
  .dropdown-check-list .anchor:after {
    position: absolute;
    content: "";
    border-left: 2px solid black;
    border-top: 2px solid black;
    padding: 5px;
    right: 10px;
    top: 20%;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
  
  .dropdown-check-list ul{
      list-style: none;
    margin: 0;
    padding: 0;
  }

  .dropdown-check-list .anchor:active:after {
    right: 8px;
    top: 21%;
  }
  
  .dropdown-check-list ul.items {
    padding: 2px;

    margin: 0;
    border: 1px solid #ccc;
    border-top: none;
  }
  
  .dropdown-check-list ul.items li {
    list-style: none;
    padding: 5px;
  } */
/*   
  .dropdown-check-list.visible .anchor {
    color: #0094ff;
  }
  
  .dropdown-check-list.visible .items {
    display: block;
  } */

:root {
  --dynamic-color: #ffffff;
  --dynamic-text-color: #333;
}
.gm-style-iw.gm-style-iw-c {
  overflow: visible;
 }
.btn-action-map.active {
  background-color: #358337 !important;
  /* background-color: #8b8b8b !important; */
  color: #fff !important;
}
#draggable {
  background: var(--dynamic-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 73px;
  height: 440px;
  border-radius: 7px;
  z-index: 9;
}
#draggable #mydraggable {
  cursor: move;
  touch-action: none;
  user-select: none;
}
#draggable #mydraggable:hover {
  cursor: grab;
}
#draggable #mydraggable:active {
  cursor: grabbing;
  /* box-shadow: 0px 30px 100px -15px red;
	 */
}
#draggable > ul {
  height: 100%;
}
#draggable > ul button {
  border-radius: 5px;
  /* color: var(--dynamic-text-color); */
  /* opacity: 0.6; */
  border: 1px solid #ffffff;
  /* box-shadow: 1px 2px 3px 3px #d0d0d0; */
  background-color: #f5f5f5;
}
#draggable > ul button:hover{
  color:#358337;
  opacity: 1;
  box-shadow: 1px 2px 3px 3px #d0d0d0;
}
#draggable > ul button:active,
#draggable > ul a.active {
  background-color: #358337 ;
  /* color: var(--bs-white); */
  opacity: 1;
  box-shadow: 1px 2px 3px 3px #d0d0d0;
}
#draggable > ul button::after {
  display: none;
}
#draggable > ul .dropdown-menu {
  transform: translate(40px, 0px) !important;
}
.no-select {
  user-select: none;
}

/**
 * ContextJS Styles
 * For use with Twitters Bootstrap CSS
 */
/**
 * ContextJS Styles
 * For use with Twitters Bootstrap CSS
 */

.dropdown-context .nav-header {
  cursor: default;
}
.dropdown-context:before,
.dropdown-context-up:before,
.dropdown-context-left:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.dropdown-context:after,
.dropdown-context-up:after,
.dropdown-context-left:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}
.dropdown-context-up:before,
.dropdown-context-up:after {
  top: auto;
  bottom: -7px;
  z-index: 9999;
}
.dropdown-context-up:before {
  border-right: 7px solid transparent;
  border-top: 7px solid #ccc;
  border-bottom: none;
  border-left: 7px solid transparent;
}
.dropdown-context-up:after {
  border-right: 6px solid transparent;
  border-top: 6px solid #ffffff;
  border-left: 6px solid transparent;
  border-bottom: none;
}
.dropdown-context-left:before {
  left: auto;
  right: 9px;
}
.dropdown-context-left:after {
  left: auto;
  right: 9px;
}

.dropdown-context-sub:before,
.dropdown-context-sub:after {
  display: none;
}
.dropdown-context .dropdown-submenu:hover .dropdown-menu {
  display: none;
}

.dropdown-context .dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.compressed-context a {
  padding-left: 14px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 13px;
}
.compressed-context .divider {
  margin: 5px 1px;
}
.compressed-context .nav-header {
  padding: 1px 13px;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.menu > li > a {
  padding: 10px 15px;
}
.dropdown-menu .nav-header {
  display: block;
  padding: 10px 15px;
  font-size: 11px;
  font-weight: bold;
  line-height: 20px;
  color: #999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
}
.dropdown-menu li > a:hover,
.dropdown-menu li > a:focus,
.dropdown-submenu:hover > a {
  color: #ffffff;
  text-decoration: none;
  background-color: #0088cc;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
}

.text-left {
  text-align: start;
}
.text-right {
  text-align: end;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link.active,
.nav.nav-pills.nav-pills-custom .nav-link.active {
  background-color: transparent;
  border: 1px dashed #d0d0d0;
  transition-duration: 1ms;
  position: relative;
}
.nav.nav-pills.nav-pills-custom .show > .nav-link,
.nav.nav-pills.nav-pills-custom .nav-link {
  border: 1px dashed var(--bs-border-dashed-color);
  border-radius: 0.625rem;
}
.btn-servicos {
}

.infoBox {
  width: 330px !important;
  height: 405px;
}
.infoBox2 {
  width: 550px !important;
  /* height: 405px; */
}

.remove {
  position: relative;
  display: block;
  float: right;
}

.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-bg: #fff;
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}
.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.marker-add{
  left: 287px;
  position: relative;
  top: 11px;
}
/* 
.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E) 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5; */
/* } */

.modal-90w {
  width: 90% !important;
  max-width: none !important;
}
.color-palette-group{
  list-style: none;
  margin:0;
  padding:0

}
.color-palette-group li{
  margin: 3px;
  padding: 2px;

}



.color-palette-outer{
  box-sizing: border-box;
}
.color-palette-outer:hover {
  background-color: rgb(58, 69, 82);
  cursor: pointer;
}
.color-range-palette__inner {    
    height: 10px;
    transform: scale(1, 1);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: justify;
    justify-content: space-between;
    overflow: hidden;
  }

  .color-range-palette__block{
    background-color: rgb(0, 147, 156);
    box-sizing: border-box;
    -webkit-box-flex: 1;
    flex-grow: 1;
  }

  .tab-report.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    /* border-radius: 0px !important; */
  }

  .nav-tabs .nav-link {
    margin-bottom:0 !important
  }

  .box-valores-mercado-revenda{
    box-shadow: 3px 3px 6px 0px #d8d8d8 !important
  }

  /* Estilos para telas pequenas (telefones) */
@media only screen and (max-width: 600px) {
  .offcanvas {
    display: none;
    position: fixed;
     width: 29.5rem; 
    height: 100%;
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    right: 0;
    top: 0;
  }
  
}

/* Estilos para telas médias (tablets) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .offcanvas {
    display: none;
    position: fixed;
     width: 40rem; 
    height: 100%;
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    right: 0;
    top: 0;
  }
  
}
/* Estilos para telas grandes (desktops) */
@media only screen and (min-width: 1025px) {
  .offcanvas {
    display: none;
    position: fixed;
     width: 40rem; 
    height: 100%;
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    right: 0;
    top: 0;
  }
}

.showMenu{
  display: block !important;
}